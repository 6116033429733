export interface SymptomsData {
  fever: boolean;
  cough: boolean;
  breathingIssues: boolean;
  fatigue: boolean;
  musclesOrBodyAches: boolean;
  newOrUnusualHeadache: boolean;
  newLossOfTasteOrSmell: boolean;
  soreThroat: boolean;
  newOrUnusualCongestionOrRunningNose: boolean;
  nauseaVomitOrOtherDigestiveSymptoms: boolean;
  diarrhea: boolean;
}

export class Symptoms implements SymptomsData {
  // eslint-disable-next-line no-useless-constructor
  constructor (
    public fever: boolean,
    public cough: boolean,
    public breathingIssues: boolean,
    public fatigue: boolean,
    public musclesOrBodyAches: boolean,
    public newOrUnusualHeadache: boolean,
    public newLossOfTasteOrSmell: boolean,
    public soreThroat: boolean,
    public newOrUnusualCongestionOrRunningNose: boolean,
    public nauseaVomitOrOtherDigestiveSymptoms: boolean,
    public diarrhea: boolean
  ) {}

  public set (property: keyof SymptomsData, value: boolean): void {
    this[property] = value
  }

  public selected (): Array<keyof SymptomsData> {
    const result = new Array<keyof SymptomsData>()

    this.addIfSelected('fever', result)
    this.addIfSelected('cough', result)
    this.addIfSelected('breathingIssues', result)
    this.addIfSelected('fatigue', result)
    this.addIfSelected('musclesOrBodyAches', result)
    this.addIfSelected('newOrUnusualHeadache', result)
    this.addIfSelected('newLossOfTasteOrSmell', result)
    this.addIfSelected('soreThroat', result)
    this.addIfSelected('newOrUnusualCongestionOrRunningNose', result)
    this.addIfSelected('nauseaVomitOrOtherDigestiveSymptoms', result)
    this.addIfSelected('diarrhea', result)

    return result
  }

  private addIfSelected (symptom: keyof SymptomsData, selected: Array<keyof SymptomsData>): void {
    if (!this[symptom]) {
      return
    }

    selected.push(symptom)
  }

  public static empty (): Symptoms {
    return new Symptoms(
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false
    )
  }

  public any (): boolean {
    return this.fever ||
      this.cough ||
      this.breathingIssues ||
      this.fatigue ||
      this.musclesOrBodyAches ||
      this.newOrUnusualHeadache ||
      this.newLossOfTasteOrSmell ||
      this.soreThroat ||
      this.newOrUnusualCongestionOrRunningNose ||
      this.nauseaVomitOrOtherDigestiveSymptoms ||
      this.diarrhea
  }

  public none (): boolean {
    return !this.any()
  }
}
