




































import { Component, Vue } from 'vue-property-decorator'
import Player from '@/components/common/Player.vue'
import HighlightText from '@/components/HighlightText.vue'
import ContentArea from '@/components/common/ContentArea.vue'
import Title from '@/components/common/page-layout/Title.vue'
import ProgressLine from '@/components/ProgressLine.vue'
import Modal from '@/components/common/Modal.vue'

@Component({
  components: {
    Player,
    HighlightText,
    ContentArea,
    Title,
    ProgressLine,
    Modal
  }
})
export default class Step3 extends Vue {
  private isModal= false
  private isText = false
  private path = 0
  private videoIsEnd (): void {
    this.isText = true
    if (this.path === 1) this.isModal = true
  }

  private mounted (): void {
    if (window.innerWidth < this.$data.mobileWidth) {
      this.$root.$on('mobile-go-back', () => {
        this.goBack()
      })
    }
  }

  private goBack (): void {
    if (this.path) this.path--
    else this.$router.push('/quidel/2/Screen_2')
  }

  get videoUrls (): Array<string> {
    return this.$store.state.video.videoUrls[5]
  }

  private openModal (): void {
    if (this.$store.getters['timer/finished'] || this.$store.getters['timer/running']) {
      this.$router.push('4')
    } else {
      this.isModal = true
    }
  }

  private destroyed (): void {
    if (window.innerWidth < this.$data.mobileWidth) this.$root.$off('mobile-go-back')
  }
}
