

















import { Component, Vue } from 'vue-property-decorator'
import SelectBox from '@/components/SelectBox.vue'
import { Symptoms, SymptomsData } from '@/store/modules/walkthrough/types/pre-test/symptoms'

@Component({
  components: {
    SelectBox
  }
})
export default class Step3 extends Vue {
  private selected: Array<keyof SymptomsData> = []

  private updateVal (ev: Array<keyof SymptomsData>): void {
    this.selected = ev
  }

  private mounted (): void {
    const previousAnswer = this.$store.getters['walkthrough/AllSymptoms']
    if (previousAnswer.length) {
      const previouslySelectedSymptoms = previousAnswer
      Array.prototype.push.apply(this.selected, previouslySelectedSymptoms);
      (this.$refs.selectBox as SelectBox).setVal(previouslySelectedSymptoms as [])
      this.selected = previouslySelectedSymptoms as []
    }
  }

  private goTo (): void {
    const symptoms = Symptoms.empty()

    this.selected.forEach((selectedSymptom) => {
      if (!Object.prototype.hasOwnProperty.call(symptoms, selectedSymptom)) {
        return
      }
      symptoms.set(selectedSymptom, true)
    })
    this.$store.dispatch('walkthrough/setSymptoms', symptoms)
    this.$router.push('4')
  }
}
